<template>
  <ProductsWrapper />
</template>

<script setup lang="ts">
import { useStore } from "~/store";
import type { Category } from "~/types/types";

const route = useRoute();
const store = {
  main: useStore(),
};

const { data } = await useAsyncData<Category>("category", async () =>
  await store.main.fetchCategoryByArg(route.params.category?.toString())
);

const { categoryMetaData } = useSeoTags();
if (data.value?.id) {
  useHead(categoryMetaData(data.value));
}
</script>